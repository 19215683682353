/* 탭바 색상 디자인 */
.message-send .ant-tabs-nav-wrap {
  background-color: transparent !important;
}

/* 클릭되지 않은 탭의 상태 */
.message-send .ant-tabs-tab {
  background-color: transparent !important;
  /* border-radius: 3px; */
  border-color: transparent !important;
}

/* 클릭된 탭의 상태 */
.message-send .ant-tabs-tab-active {
  background-color: transparent !important;
  /* border-radius: 3px; */
  /* border-color: #dd8739 !important;  */
}

/* 클릭되지 않은 탭의 글자 상태 */
.message-send .ant-tabs-tab .ant-tabs-tab-btn {
  color: #a4a4a4 !important;
  font-size: 16px;
  line-height: 23px;
}

/* 클릭된 탭의 글자 상태 */
.message-send .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
  font-size: 16px;
  line-height: 23px;
}

.message-send .ant-tabs-ink-bar {
  position: absolute;
  background: #dd8739;
  pointer-events: none;
}

.message-send .ant-select {
  color: #ffffff;
}

.message-send .ant-select-arrow {
  color: #ffffff;
}

.message-send .ant-tabs-bottom > .ant-tabs-nav:before,
.ant-tabs-bottom > div > .ant-tabs-nav:before,
.ant-tabs-top > .ant-tabs-nav:before,
.ant-tabs-top > div > .ant-tabs-nav:before {
  border-bottom: none;
}
