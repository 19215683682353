html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Noto Sans KR-Regular", sans-serif;
}

summary::marker {
  display: none;
  content: "";
}

/* .custom-header {
  background-color: #222222;
}


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0px;
} */

/* .ant-input-number-input,
.ant-input-number {
  border: 1px solid #4a4a4a !important;
  text-align: right !important;
  background: #4a4a4a !important;
} */

/* 레이아웃 디자인 */

.ant-layout {
  background-color: #222222;
  width: 100%;
  min-height: calc(100% - 90px);
  padding: 24px;
  border-radius: 3px;
}

/* 레이아웃의 헤더 디자인 */
.ant-layout-header {
  background: transparent;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 레이아웃의 컨텐츠 디자인 */
.ant-layout-content {
  width: 100%;
  position: relative;
  overflow: auto;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

/* 클릭되지 않은 탭의 상태 */
.ant-tabs-tab {
  background-color: #121212 !important;
  border-radius: 3px;
  border-color: transparent !important;
  padding-bottom: 6px;
}

/* 클릭된 탭의 상태 */
.ant-tabs-tab-active {
  background-color: #222222 !important;
  border-radius: 3px;
  border-color: transparent !important;
}

/* 클릭되지 않은 탭의 글자 상태 */
.ant-tabs-tab .ant-tabs-tab-btn {
  color: #ffffff !important;
  font-size: 16px;
  line-height: 23px;
}

/* 클릭된 탭의 글자 상태 */
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fa943d !important;
  font-size: 16px;
  line-height: 23px;
}

/* 탭들 아랫 부분 디자인 */
.ant-tabs-card {
  background-color: #222222 !important;
}

/* 탭바 색상 디자인 */
.ant-tabs-nav-wrap {
  background-color: #121212 !important;
}

/* thead부분 border */
.ant-table {
  border: 1px solid #4a4a4a;
  background: transparent;
}

/* 최소 높이 지정 */
.ant-table-expanded-row-fixed {
}

/* thead 데이터의 높이 지정 및 border 디자인 */
.ant-table-thead > tr > th {
  position: relative;
  color: #fff;
  font-weight: 500;
  text-align: left;
  background: #121212;
  border: 1px solid #4a4a4a;
  transition: background 0.3s ease;
  text-align: center;
  height: 42px !important;
  padding: 0;
}

/* tbody 데이터의 높이 지정 맟 border 디자인 */
.ant-table-tbody > tr > td {
  height: 42px;
  border: 1px solid #4a4a4a;
  background: #222222 !important;
  color: #fff;
  font-size: 14px;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  border-color: #4a4a4a;
}

/* 체크박스의 너비 디자인 */
.ant-table-selection-column {
  width: 40px;
}

.ant-table-cell {
  padding: 0 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent;
}

.ant-pagination {
  display: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-hover {
  background: #222222 !important;
}

.ant-table-placeholder {
  display: none;
}

/* Form의 span 태그 폰트 컬러 디자인 */
.ant-form span {
  color: #ffffff;
}

/* Input 라인 디자인 */
.ant-input {
  /* width: 681px;
  height: 45px; */
  border-radius: 5px;
  background: #4a4a4a;
  border: none;
  color: #ffffff;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: #4a4a4a;
  border-color: #222222;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover {
  background: #4a4a4a;
  border-color: #222222;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  box-shadow: none;
  border: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  box-shadow: none;
  border: none;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: #4a4a4a;
  border-color: #222222;
}

.ant-form-item-explain {
  position: absolute;
  background-color: transparent;
  width: 400px;
  font-size: 11px;
  color: #fa943d;
}

/* 토글 디자인 */
.ant-switch {
  height: 16px;
  min-width: 16px;
}

.ant-switch-checked {
  background: #fa6400;
}

.ant-switch-checked:focus {
  box-shadow: none;
}

/* 애니메이션 효과를 적용할 대상 요소 */
.ant-switch-handle::before {
  /* 애니메이션 속성 */
  content: "";
  position: absolute;
  top: -0.4px;
  left: 0.5px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-switch-checked .ant-switch-handle {
  transform: translateX(5px);
}

/* Input 라인을 클릭했을 때 효과 제거 */
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
  border-color: #d9d9d9;
}

/* 텍스트가 영역 밖으로 넘어갈 경우, 넘어간 부분을 (...)로 대체 */
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-size: 14px;
}

.ant-input[disabled] {
  background-color: #4a4a4a;
  color: #fff;
}

/* Radio 버튼을 클릭했을 때 inner 버툰 디자인  */
.ant-radio-inner::after {
  background: #fa943d;
  border-color: none;
}

/* Radio 버튼의 inner 디자인 */
.ant-radio-inner {
  background: transparent;
  border-color: none;
}

/* Radio 버튼의 border 디자인*/
.ant-radio-checked .ant-radio-inner {
  border-color: #fa943d;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio-checked:after {
  border: transparent;
}

/* radio 버튼 클릭 시 box shadow 제거 */
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio:hover + span,
.ant-radio-wrapper:hover .ant-radio + span {
  border-color: #fa943d !important;
}

.ant-radio-disabled .ant-radio-inner {
  background-color: #303030;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: #fa943d;
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #fa943d;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox:hover + span,
.ant-checkbox-wrapper:hover .ant-checkbox + span {
  box-shadow: none;
  border-color: transparent;
}

.ant-checkbox-input {
  border: #fa943d;
}

.ant-checkbox-checked::after {
  border: #fa943d !important;
}

.ant-checkbox-checked::selection {
  background: #fa943d !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fa943d;
  border-color: #fa943d;
}

.ant-upload.ant-upload-select-text {
  background-color: #4a4a4a;
  border: none;
  width: 288px;
  height: 45px;
  border-radius: 22.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-upload.ant-upload-select-text:hover {
  cursor: pointer;
}

.ant-picker {
  background: #4a4a4a;
  border: none;
  border-radius: 5px;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-input > input {
  color: #ffffff;
}

.ant-picker-today-btn {
  color: #fa943d;
}

/* 선택한 날짜를 지울 떄 사용하는 clear 기능 삭제 */
.ant-picker-clear {
  display: none;
}

/* 날짜 선택 팝업창 header 디자인 */
.ant-picker-header {
  background: #303030;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
}

.ant-picker-header button {
  color: #fff;
}

.ant-picker-header-view button:hover {
  color: #fa943d;
}

/* 날짜 선택 팝업창 body 디자인 */
.ant-picker-body {
  background: #303030;
  border: 1px solid #ffffff;
}

/* 날쩌 선택 팝업창의 요일 디자인 */
.ant-picker-body th {
  color: #ffffff;
}

/* 날짜 선택 팝업창 footer 디자인 */
.ant-picker-footer {
  background: #303030;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.ant-picker-cell-in-view {
  color: #ffffff;
}

.ant-picker-suffix {
  color: #ffffff;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #2c2c2c;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #222222;
  border: 1px solid #fa943d;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid #fa943d;
}

.ant-picker-today-btn:hover {
  color: #fa943d;
}

.ant-picker.ant-picker-disabled {
  background: #4a4a4a;
  border-color: transparent;
}
.ant-picker-input > input[disabled] {
  color: #fff;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-select * {
  border-color: #121212 !important;
  outline: #121212;
}

/* select 버튼과 arrow 가운데 정렬 */
.ant-select {
  width: 329px;
  height: 45px;
  background: #4a4a4a;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background: #4a4a4a;
  border: none;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selector:focus {
  border: none;
}

/* Select 버튼을 클릭 시 생기는 box shadow 제거 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
}

/* select 버튼을 클릭했을 때, 선택되어져 있는 폰트의 디자인 */
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
}

/* 선택되어 있는 버튼의 background-color 디자인 */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400;
  background-color: transparent;
}

/* 선택되어 있는 아이템에 마우스를 올려두었을 때 디자인 */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  background-color: #5c5c5c;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background-color: #5c5c5c;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #4a4a4a;
}

/* 드롭다운 컨테이너의 디자인 */
.ant-select-dropdown {
  padding: 0;
  border-radius: 5px;
  background-color: #4a4a4a;
}

/* 드롭다운 버튼의 폰트 디자인 */
.ant-select-item-option-content {
  color: #fff;
}

/* 드롭다운 아이템의 높이 수정 및 중앙 정렬 */
/* .ant-select-item {
  min-height: 45px !important;
  align-items: center;
} */

/* 드롭다운 컨테이너와 Select 컴포넌트 사이의 거리
.ant-select-dropdown-placement-bottomLeft {
  top: 48px !important;
}

.ant-select-dropdown-placement-topLeft {
  top: -138px !important;
} */

/* 말풍선 디자인 */
.ant-popover {
  width: 222px;
  height: 50px;
}

/* 말풍선의 위치 수정 및 디자인 수정 */
.ant-popover-content {
  left: 30px;
  bottom: 20px;
  border-radius: 4px;
}

/* 말풍선 안의 컨텐츠 내용 디자인 */
.ant-popover-inner-content {
  padding: 12px;
  padding-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.670707px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 35px;
}

.ant-spin-dot-item {
  background-color: #fa943d;
  transform: scale(1);
}

/* div::-webkit-scrollbar {
  display: none;
} */

.ant-empty {
  display: none;
}

/* 구분선 */
.ant-divider {
  border: 1px solid #4a4a4a;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

@font-face {
  font-family: "Noto Sans KR-Regular";
  src: url("./fonts/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans KR-Bold";
  src: url("./fonts/NotoSansKR-Bold.otf") format("truetype");
}
