/* .message-setting-modal .ant-form-item {
  padding: 0;
  margin: 0;
} */
/* 클릭되지 않은 탭의 상태 */
.message-setting-modal .ant-tabs-tab {
  background-color: transparent !important;
  border-radius: 3px;
  height: 14px;
}

/* 클릭되지 않은 탭의 글자 상태 */
.message-setting-modal .ant-tabs-tab .ant-tabs-tab-btn {
  color: #a4a4a4 !important;
  font-size: 14px;
  line-height: 23px;
  padding-bottom: 8px;
}

/* 클릭된 탭의 글자 상태 */
.message-setting-modal .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-size: 14px;
  line-height: 23px;
  border-bottom: 1px solid #fa943d;
  padding-bottom: 8px;
}

/* 탭들 아랫 부분 디자인 */
.message-setting-modal .ant-tabs-card {
  background-color: transparent !important;
}

.message-setting-modal .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none !important;
}

/* 탭바 색상 디자인 */
.message-setting-modal .ant-tabs-nav-wrap {
  background-color: transparent !important;
}

.message-setting-modal .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  padding-right: 24px;
}

.message-setting-modal .ant-tabs-nav-more {
  visibility: hidden;
  width: 0;
}

.message-setting-modal .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  padding: 0;
}

.message-setting-modal .ant-tabs-ink-bar {
  position: absolute;
  background: #dd8739;
  pointer-events: none;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
}

.message-setting-modal .ant-form-item {
  padding: 0;
  margin: 0;
}
.message-setting-modal .ant-input-number-handler-wrap {
  display: none;
}

.message-setting-modal .ant-input-number {
  background: #4a4a4a;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.message-setting-modal .ant-input-number-input {
  text-align: center;
}

.jgcp_period_end .ant-input-number-input {
  text-align: center;
}

.message-setting-modal /* Input 라인을 클릭했을 때 효과 제거 */
  .ant-input-number-focus,
  .ant-input-number-focused {
  box-shadow: none !important;
}

.message-setting-modal .ant-checkbox-disabled .ant-checkbox-inner {
  background: #999999;
  border-color: #999999 !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox + span {
  color: #999999;
}

/* 말풍선 디자인 */
.ad_info_popover .ant-popover {
  width: 400px;
}

/* 말풍선의 위치 수정 및 디자인 수정 */
.ad_info_popover .ant-popover-content {
  border-radius: 4px;
  position: absolute;
}

/* 말풍선 디자인 */
.auto_input_popover .ant-popover {
  width: 360px;
}

/* 말풍선의 위치 수정 및 디자인 수정 */
.auto_input_popover .ant-popover-content {
  border-radius: 4px;
  position: absolute;
}
.message-setting-modal .disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ant-message {
  z-index: 10500;
}
