/* 클릭되지 않은 탭의 상태 */
.createAndModifyCouponModal .ant-tabs-tab {
  background-color: transparent !important;
  border-radius: 3px;
}

/* 클릭되지 않은 탭의 글자 상태 */
.createAndModifyCouponModal .ant-tabs-tab .ant-tabs-tab-btn {
  color: #a4a4a4 !important;
  font-size: 14px;
  line-height: 23px;
}

/* 클릭된 탭의 글자 상태 */
.createAndModifyCouponModal .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-size: 14px;
  line-height: 20px;
}

.createAndModifyCouponModal .ant-form-item-explain-error {
  display: flex;
}

/* 탭들 아랫 부분 디자인 */
.createAndModifyCouponModal .ant-tabs-card {
  background-color: transparent !important;
}

.createAndModifyCouponModal .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none !important;
}

/* 탭바 색상 디자인 */
.createAndModifyCouponModal .ant-tabs-nav-wrap {
  background-color: transparent !important;
}

.createAndModifyCouponModal .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  padding-right: 24px;
}

.createAndModifyCouponModal .ant-tabs-nav-more {
  visibility: hidden;
  width: 0;
}

.createAndModifyCouponModal .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  padding: 0;
}

.createAndModifyCouponModal .ant-tabs-ink-bar {
  position: absolute;
  background: #dd8739;
  pointer-events: none;
}

.createAndModifyCouponModal .platform-tab .ant-tabs-ink-bar {
  display: none;
}

.createAndModifyCouponModal .ant-tabs-tab {
  padding: 0 !important;
}

.createAndModifyCouponModal
  .platform-tab
  .ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fa9430 !important;
}

.createAndModifyCouponModal .ant-form-item {
  padding: 0;
  margin: 0;
}
.createAndModifyCouponModal .ant-input-number-handler-wrap {
  display: none;
}

.createAndModifyCouponModal .ant-input-number {
  background: #4a4a4a;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.createAndModifyCouponModal .ant-input-number-input {
  text-align: center;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-in-range:hover {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background: transparent;
}

.createAndModifyCouponModal .ant-picker-range .ant-picker-active-bar {
  background: #fa943d;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before {
  background: transparent;
}

.createAndModifyCouponModal .jgcp_period_end .ant-input-number-input {
  text-align: center;
}
.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner:after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner:after {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start:before {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner:after {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end:before {
  background: transparent;
}
.createAndModifyCouponModal .ant-picker-cell-in-view.ant-picker-cell-range {
  border: none;
}

.createAndModifyCouponModal .ant-picker-range-separator {
  border-color: transparent !important;
}

.createAndModifyCouponModal .ant-picker-focused .ant-picker-range-separator {
  border-color: transparent !important;
}

.createAndModifyCouponModal .ant-picker-panel {
  box-shadow: none !important;
}

.createAndModifyCouponModal .ant-picker-cell-inner:hover {
  background: hsla(0, 0%, 100%, 0.2) !important;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: #fa943d;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background: #fa943d;
}

.createAndModifyCouponModal
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner {
  background: transparent;
}

.createAndModifyCouponModal
  .ant-form-item-control-input-content
  .ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-topLeft {
  top: -300px !important;
}

.createAndModifyCouponModal
  .ant-picker-panel
  .ant-picker-panel-has-range-hover {
  border-color: #fa943d;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 2px;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 2px;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
  border-top: 1px dashed #fa943d; /* 원하는 색상으로 변경 */
  border-bottom: 1px dashed #fa943d;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  ):after {
  border-top: 1px dashed #fa943d !important;
  border-bottom: 1px dashed #fa943d !important;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
    .ant-picker-cell-range-hover-edge-end-near-range
  ):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
  border-right: 1px dashed #fa943d !important;
}

.createAndModifyCouponModal
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
    .ant-picker-cell-range-hover-edge-start-near-range
  ):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
  border-left: 1px dashed #fa943d !important;
}

.createAndModifyCouponModal /* Input 라인을 클릭했을 때 효과 제거 */
.ant-input-number-focus,
.ant-input-number-focused {
  box-shadow: none !important;
}
